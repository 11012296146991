import React from 'react';


const Preloader = props => {
    
    return (

<div id="preloader"></div>
        
)
}

setTimeout(() => {
    document.getElementById('preloader').setAttribute('class','hide')
}, 3000);

export default Preloader;