import React from 'react';
import { Link } from 'react-router-dom';



const Breadcumb = props => {

  var breadClass;

      if (props.pageTitle === "About us") {
         breadClass = "page-area about"
      }
      if (props.pageTitle === "Services") {
         breadClass = "page-area service"
      }
      if (props.pageTitle === "Project") {
         breadClass = "page-area project"
      }
      if (props.pageTitle === "Contact us") {
         breadClass = "page-area contact"
      }

    return (
        <div className={breadClass}>
            <div className="breadcumb-overlay" />
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="breadcrumb text-center">
                            <div className="section-headline white-headline text-center">
                                <h3>{props.pageTitle}</h3>
                            </div>
                            <ul>
                                <li className="home-bread"><Link to={'/'}>Home</Link></li>
                                <li>{props.pageTitle}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Breadcumb
