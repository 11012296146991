import React from 'react'

const Tabarea = props => {

    console.log(props);

    return (
        <div className="tab-area bg-color fix area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="tab-menu">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="active">
                                    <a href="#p-view-1" role="tab" data-toggle="tab">
                                        <span className="cha-title">Sales & CRM</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#p-view-2" role="tab" data-toggle="tab">
                                        <span className="cha-title">Competition Analysis</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#p-view-3" role="tab" data-toggle="tab">
                                        <span className="cha-title">IT Support</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#p-view-4" role="tab" data-toggle="tab">
                                        <span className="cha-title">Brand Promotion</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="tab-content">
                            <div className="tab-pane active" id="p-view-1">
                                <div className="tab-inner">
                                    <div className="single-machine row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="tabe-img">
                                                <img src="assets/img/services/s1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="machine-text">
                                                <h3>CRM solutions and sales management</h3>
                                                <p>We would, demonstrate how companies use CRM to enhance customer experience solutions to understand thier audience, engage with customers, and deliver on thier brand’s promise.</p>
                                                <ul>
                                                    <li><a href="#">Power customer relationship management with real-time customer data</a></li>
                                                    <li><a href="#">Give your customers the transparency and control they demand</a></li>
                                                    <li><a href="#">Save time and guesswork to accelerate sales</a></li>
                                                    <li><a href="#">Create a unified service experience</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="p-view-2">
                                <div className="tab-inner">
                                    <div className="single-machine row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="tabe-img">
                                                <img src="assets/img/services/s2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="machine-text">
                                                <h3>Keyword Research to beat the competitor</h3>
                                                <p>Search for your competitor. See every keyword they've ever bought on Google and every ad test they've run. Make their successes your own and avoid their mistakes to rank higher on search engine.</p>
                                                <ul>
                                                    <li><a href="#">Research Competitors target Keywords</a></li>
                                                    <li><a href="#">Primary Source to reach your customers</a></li>
                                                    <li><a href="#">Result in Higher Conversion Rate</a></li>
                                                    <li><a href="#">Builds your Brand Credibility</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="p-view-3">
                                <div className="tab-inner">
                                    <div className="single-machine row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="tabe-img">
                                                <img src="assets/img/services/s3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="machine-text">
                                                <h3>Innovation idea latest business tecnology</h3>
                                                <p>We can help you build your App idea, create wireframes, design mock-ups and deliver the Most Viable Product that establishes your Proof of Concept. This delivery model works for everyone including Start-ups, Medium Enterprises and Large Businesses.</p>
                                                <ul>
                                                    <li><a href="#">Innovation idea latest business tecnology</a></li>
                                                    <li><a href="#">Work remotely with automated bots from anywhere</a></li>
                                                    <li><a href="#">Safe secure services like online email account</a></li>
                                                    <li><a href="#">Provide smooth user experience without worries</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="p-view-4">
                                <div className="tab-inner">
                                    <div className="single-machine row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="tabe-img">
                                                <img src="assets/img/services/s4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="machine-text">
                                                <h3>Digital content marketing online clients platform</h3>
                                                <p>Communicate with clients through our marketing strategy to influence the buyers to choose your brand while making the decision.Do it with the purpose of promoting brand awareness to persuade consumer's</p>
                                                <ul>
                                                   <li><a href="#">Reaching whole lot of consumers</a></li>                                                 
                                                   <li><a href="#">Spread awareness for your brand</a></li>                                                  
                                                   <li><a href="#">Increase your brand credibility</a></li>                                                    
                                                   <li><a href="#">Ideal way to capture attention</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tabarea
